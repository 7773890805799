.mobile-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: var(--accent-color);
  transform: translateY(-100%);
  transition: ease-in-out 0.3s;
}

.mobile-navbar .center-container {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

.mobile-navbar.hidden {
  transform: translateY(-100%);
}

.mobile-navbar.visible {
  transform: translateY(0%);
}

.mobile-navbar li {
  list-style-type: none;
}

.mobile-navbar a {
  display: inline-block;
  position: relative;
  color: var(--background-color);
  transition: ease-in-out 0.3s;
  transition-delay: 0.3s;
  z-index: 2;
  text-decoration: none;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
}

.mobile-navbar #active:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  height: 2px;
  background-color: var(--background-color);
  transition: all 0.3s;
}

.mobile-navbar a:visited {
  color: var(--background-color);
}

.mobile-navbar a.hidden {
  transform: translateY(60px);
}

.mobile-navbar .link-hider {
  display: block;
  position: relative;
  height: 80px;
  background-color: var(--accent-color);
  z-index: 3;
}

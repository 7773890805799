@import "src/media-queries";

.project-card {
  text-decoration: none;
  margin-bottom: var(--space-xl);

  &__container {
    box-sizing: border-box;
    display: flex;

    width: 100%;
    padding: var(--space-lg);
    box-shadow: var(--box-shadow);
    border-radius: var(--space-md);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    @include md-max {
      // Overwrite inline component style
      flex-direction: column !important;
    }
  }

  &__container:hover {
    transform: scale(1.002, 1.002);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 5px 5px rgba(0, 0, 0, 0.15);
  }

  &__image {
    width: 35%;
    object-fit: cover;
    border-radius: var(--space-md);
    // box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
    //   0 4px 8px 3px rgba(60, 64, 67, 0.15);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    @include md-max {
      width: 100%;
      margin: 0 !important;
      margin-bottom: var(--space-md) !important;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-md);

    @include sm-max {
      margin-bottom: 0;
    }
  }

  &__logo {
    width: 35%;
    max-width: 50px;
    margin-right: var(--space-md);
  }

  &__tags {
    @include sm-max {
      display: none;
    }
  }

  &__description {
    margin-bottom: var(--space-lg);
    @include sm-max {
      display: none;
    }
  }

  &__title {
    font-size: 24px;
  }

  &__subtitle {
    font-weight: 500;
    color: var(--secondary-color);
  }

  &__spacer {
    width: var(--space-md);
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;

    @include sm-max {
      display: none;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromBelow {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
}

@keyframes scaleHorizontal {
  from {
    transform: scaleX(0%);
  }
  to {
    transform: scaleX(100%);
  }
}

.fadeIn {
  animation: fadeIn 1s linear backwards;
}

.slideInFromBelow {
  animation: slideInFromBelow 1s ease backwards;
}

.fadeIn-slideInFromBelow {
  animation: fadeIn 0.8s linear backwards, slideInFromBelow 0.8s ease backwards;
}

.scaleLeftToRight {
  animation: scaleHorizontal 0.5s ease backwards;
  transform-origin: 50% 0%;
}

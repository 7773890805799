.tags-list-flat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tags-list-flat h4 {
  margin-bottom: 10px;
}

.tags-list-flat ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.tags-list-flat ul li {
  margin: 5px 10px 5px 0px;
  padding: 7px 12px 7px 12px;
  list-style-type: none;
  white-space: nowrap;
  border-radius: 100px;
  color: white;
  background-color: #212121;
}

.tags-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tags-list h4 {
  margin-bottom: 10px;
}

.tags-list ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.tags-list ul li {
  margin: 10px 20px 10px 0px;
  padding: 10px;

  list-style-type: none;
  white-space: nowrap;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 0 2px rgba(0, 0, 0, 0.08);
  font-weight: 500;
  /*
        smooth box shadow
        0 5px 10px rgba(154, 160, 185, .05), 0 15px 40px rgba(166, 173, 201, .2);
    */
}

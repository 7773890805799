.project-page {
  display: flex;
}

.project-page-header-image {
  border-radius: 15px;
}

.project-page-skeleton {
  background-color: var(--secondary-color);
}

.project-page p {
  text-align: start;
  margin-bottom: 30px;
}

.project-page-subtitle {
  margin-bottom: 20px;
  font-size: 18px;
}

.project-page-links {
  display: flex;
}

.project-page-links button {
  margin-top: 20px;
  margin-right: 20px;
}

.project-page-links button:last-child {
  margin-right: 0;
}

/* Mobile Styles */

@media only screen and (max-width: 500px) {
  .project-page {
    flex-direction: column;
  }
  .project-page-header-image {
    margin-bottom: 20px;
    width: 100%;
  }
  .project-page-links {
    justify-content: center;
  }
  .project-page-links button {
    width: 115px;
  }
}

/* Tablet Styles */

@media only screen and (min-width: 500px) and (max-width: 1000px) {
  .project-page {
    flex-direction: column;
  }
  .project-page-header-image {
    margin-bottom: 20px;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .project-page-links {
    justify-content: center;
  }
  .project-page-links button {
    width: 150px;
  }
}

/* Desktop Styles */

@media only screen and (min-width: 1000px) {
  .project-page-links {
    justify-content: flex-start;
  }
  .project-page-links button {
    width: 150px;
  }
  .project-page-header-image {
    width: 50%;
    height: 50%;
    margin-right: 30px;
  }
}

.section-header {
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  font-size: 30px;
}

/* Mobile Styles */

@media only screen and (max-width: 500px) {
  .section-header {
    margin: 25px 0;
  }
}

/* Tablet Styles */

@media only screen and (min-width: 500px) and (max-width: 1000px) {
  .section-header {
    margin: 40px 0;
  }
}

/* Desktop Styles */

@media only screen and (min-width: 1000px) {
  .section-header {
    margin: 50px 0;
  }
}

.custom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 2px solid var(--main-color);
  border-radius: 10px;
  background-color: #00000000;
  color: var(--main-color);
  font-size: 16px;
  font-weight: bold;
  text-align: start;
  outline: 0;
  transition: ease-in-out 0.15s;
  cursor: pointer;
}

.custom-button * {
  cursor: pointer;
}

.custom-button:hover {
  background-color: var(--main-color);
  color: var(--background-color);
}

.custom-button img {
  height: 25px;
  margin-right: 5px;
  transition: ease-in-out 0.15s;
}

.custom-button:hover img {
  filter: invert(1);
}

.motto-section {
  margin-bottom: 100px;
}

.motto-section-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
}

.motto-spacer {
  flex: 0.6;
}

.motto-connector {
  height: 50px;
  flex: 0.6;
}

.motto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  min-height: 250px;
}

.motto-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.motto-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: black;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 100%;
}

.motto-img img {
  width: 30px;
  height: 30px;
  filter: invert(100%);
}

.motto-header h4 {
  text-align: start;
  /* border-left: 2px solid var(--main-color); */
}

.motto-header img {
  height: 40px;
}

.motto-description {
  margin-top: 15px;
  text-align: center;
}

.divider {
  flex: 0.4;
}

/* Mobile Styles */

@media only screen and (max-width: 500px) {
  .motto-section-content {
    flex-direction: column;
  }
  .motto {
    margin: 20px 0;
    padding: 0% 10%;
  }
  .motto-connector {
    display: none;
  }
}

/* Tablet Styles */

@media only screen and (min-width: 500px) and (max-width: 1000px) {
  .motto-section-content {
    flex-direction: column;
  }
  .motto {
    margin: 25px 0;
    padding: 10% 15%;
  }
  .motto-connector {
    display: none;
  }
}

/* Desktop Styles */

@media only screen and (min-width: 1000px) {
  .motto-section-content {
    padding: 0 40px;
  }
}

footer {
  position: relative;
  background-color: var(--accent-color);
  color: var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer a {
  color: var(--background-color);
}

footer a:hover {
  text-decoration: var(--background-color);
  text-decoration-line: underline;
}

.contact-section {
  display: flex;
  justify-content: space-between;
  font-style: normal;
}

.contact-section div {
  margin-right: 15px;
}

.social-media-links {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.social-media-links img {
  width: 50px;
  color: var(--background-color);
  fill: currentColor;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
}

.social-media-links img:hover {
  transform: scale(1.2);
}

.scroll-top-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 60px;
  height: 60px;
  top: -30px;
  right: 0;
  background-color: var(--background-color);
  border-radius: 100%;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
    0 4px 8px 3px rgba(60, 64, 67, 0.15);
  outline: 0;
  cursor: pointer;
}

.scroll-top-button img {
  height: 30px;
  width: 30px;
}

.copyright-text {
  font-size: 12px;
  margin-top: 100px;
  text-align: center;
}

/* Mobile Styles */

@media only screen and (max-width: 500px) {
  footer {
    padding: 50px var(--page-margin-mobile) 20px var(--page-margin-mobile);
  }
  .scroll-top-button {
    right: var(--page-margin-mobile);
  }
  .contact-section {
    flex-direction: column;
  }
  .contact-section div {
    margin-bottom: 20px;
  }
  .copyright-text {
    margin-top: 60px;
    text-align: center;
  }
}

/* Tablet Styles */

@media only screen and (min-width: 500px) and (max-width: 1000px) {
  footer {
    padding: 50px var(--page-margin-tablet) 20px var(--page-margin-tablet);
  }
  .scroll-top-button {
    right: var(--page-margin-tablet);
  }
  .contact-section {
    flex-direction: column;
    align-items: baseline;
  }
  .contact-section div {
    margin-bottom: 20px;
  }
}

/* Desktop Styles */

@media only screen and (min-width: 1000px) {
  footer {
    padding: 50px var(--page-margin-desktop) 20px var(--page-margin-desktop);
  }
  .scroll-top-button {
    right: var(--page-margin-desktop);
  }
}
